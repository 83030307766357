import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import linkStyle from "../pages/links.module.scss"
import Head from "../components/head"

const NotFound = () => {
  return (
    <Layout>
      <Head title="404" />
      <h1>Page not found</h1>
      <p>
        <Link className={linkStyle.for_oh_for} to="/">
          Head home
        </Link>
      </p>
    </Layout>
  )
}

export default NotFound
